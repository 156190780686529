<template>
  <Menu id="Nav" :theme="$store.getters.getTheme" @click="clickItem" mode="inline">
    <div class="logo" >Power Sky Cafe Limited</div>
    <MenuItem key="Dashboard">
      <DashboardOutlined />
      <span>{{$t('nav.name.Dashboard')}}</span>
    </MenuItem>
    <template v-for="(item) in navList" :key="item.name">
      <MenuItem v-if="!item.children" :key="item.name">
        <ControlOutlined v-if="item.name == 'Auth'" />
        <TeamOutlined v-else-if="item.name == 'Member'" />
        <UserOutlined v-else-if="item.name == 'Users'" />
        <UserOutlined v-else-if="item.name == 'UserManage'" />
        <UploadOutlined v-else-if="item.name == 'Uploads'" />
        <PhoneOutlined v-else-if="item.name == 'PhoneCountry'" />
        <BankOutlined v-else-if="item.name == 'CompanyManage'" />
        <ControlOutlined v-else />
        <span>{{$t('nav.name.'+item.name)}}</span>
      </MenuItem>
      <MenuSub v-else :key="item.name">
        <template #title>
          <span>
            <ControlOutlined v-if="item.name == 'Auth'" />
            <TeamOutlined v-else-if="item.name == 'Member'" />
            <UserOutlined v-else-if="item.name == 'Users'" />
            <UserOutlined v-else-if="item.name == 'UserManage'" />
            <UploadOutlined v-else-if="item.name == 'Uploads'" />
            <PhoneOutlined v-else-if="item.name == 'PhoneCountry'" />
            <BankOutlined v-else-if="item.name == 'CompanyManage'" />
            <ControlOutlined v-else />
            <span>{{$t('nav.name.'+item.name)}}</span>
          </span>
        </template>
        <MenuItem v-for="v in item.children" :title="$t('nav.name.'+v.name)" :key="v.name">{{$t('nav.name.'+v.name)}}</MenuItem>
      </MenuSub>
    </template>
  </Menu>
</template>

<script>
import { Menu, notification } from "ant-design-vue";
import { LogoutOutlined, UserOutlined, DashboardOutlined, ControlOutlined, TeamOutlined, UploadOutlined, PhoneOutlined, BankOutlined } from "@ant-design/icons-vue";
import { NavList } from "@/api/NavList"

export default {
  name: "Nav",
  components: {
    LogoutOutlined,
    UserOutlined,
    DashboardOutlined,
    ControlOutlined,
    TeamOutlined,
    UploadOutlined,
    PhoneOutlined,
    BankOutlined,
    Menu,
    MenuItem: Menu.Item,
    MenuSub: Menu.SubMenu,
  },
  data() {
    return {
      navList: null,
    };
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
      location.reload();
    },
    clickItem(item) {
      if (!item) return false;
      if (this.$router.hasRoute(item.key)) {
        this.$router.push({
          name: item.key,
        });
      } else {
        notification.warning({
          message: this.$t('public.name.no_page'),
        });
      }
    },
    get_user_nav() {
      //此处配置角色路由，可从后台获取，可前端写死
      // this.navList = [
      //         { name:"AnnualLeavePlanManage"},
      //   { name:"AttendanceManage"},
      //   { name:"AuthorityManage"},
      //   { name:"DepartmentManage"},
      //   { name:"EmployeeManage"},
      //   { name:"EmployeeEntryRecordManage"},
      //   { name:"HolidayManage"},
      //   { name:"LeaveManage"},
      //   { name:"OvertimeApplicationManage"},
      //   { name:"RemainingVacationManage"},
      //   { name:"ReportManage"},
      //   { name:"RoleManage"},
      //   { name:"SalaryInformationManage"},
      // ]
      let api = new NavList();
      api.get().then((response) => {
        if (0 == parseInt(response.data.code)) {
          this.navList = response.data.data;
          // this.navList.push({name: "CifManage"})
          // this.navList.push({name: "PavementManage"})
          // this.navList.push({name: "RoboticControManage", children:[
          //   {name: "RemoteOperationManage"},
          //   {name: "ConeDispenseCollectManage"},
          //   {name: "TunnelWashingManage"},
          //   {name: "PlantsCroppingManage"},
          //   {name: "RoadMarkingManage"}
          // ]})
          // this.navList.push({name: "TestingEquationManage"})
          // this.navList.push({name: "UtilityManage"})
          // this.navList.push({name: "MaterialCategoriesManage"})
          // this.navList.push({name: "MobileDeviceManage"})
          // this.navList.push({name: "ReportingManage"})
          // this.navList.push({name: "IdentityManage"})
          // this.navList.push({name: "RoadStudManage"})
          // this.navList.push({name: "NamiResearchedManage"})
          // this.navList.push({name: "AnalyticsCombinationManage"})
          // this.navList.push({name: "AIBankManage"})
          // this.navList.push({name: "TestingMaterialManage"})
          // this.navList.push({name: "TestingMachineManage"})
          // this.navList.push({name: "LoraWanManage"})
        } else {
          this.navList = [];
        }
      });
      //直接读取用户type来区分菜单
      // var type = this.$store.getters.getType
      // //后台人员
      // if(type === 0){
      //   this.navList = [{ name:"UserManage"},{ name:"CompanyManage"}]
      // }else{
      //   this.navList = [{ name:"CompanyManage"}]
      // }
    },
  },
  mounted() {
    this.get_user_nav();
  },
}
</script>


<style lang="less">
@import url("~@/assets/less/components/nav.less");
</style>